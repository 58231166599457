<template>
  <div class="page">
    <v-card :elevation="0" class="print-order-default">
      <div class="header">
        <div class="logo" style="background: url('/img/label-logo.png') center center / contain no-repeat;">
        </div>
        <div class="epacket-barcode">
          <div style="text-align: center; margin-top: 1mm;">ISO TRACKING - cargo.epacket.vn</div>
          <div class="block-barcode">
            <VueBarcode v-if="order.code !== null" style="top: 50%;left: 50%;transform: translate(0%, -30%);"
                        :width="3"
                        :font-size="30"
                        :value="order.code">
              {{ order.code }}
            </VueBarcode>
          </div>
        </div>
      </div>
      <div class="label-title">{{ labelTitle }}</div>
      <div class="label-content">
        <div class="label-from">
          <div class="label-address">
            From: <br>
            {{ order.customer.name }} <br>
            {{ order.customer.address }} <br>
            Order No: {{ order.name }}
          </div>
          <div class="label-items">
            {{ '2021-09-14' }}<br>
            <template v-for="item in order.order_items">
              {{ item.product.name }}<br :key="item.id">
            </template>
            {{ order ? order.weight + 'g' : '' }}<br>
          </div>
        </div>
        <hr style="border-top: 1px dashed black; margin: 0.3cm 0;">
        <div class="label-to">
          <div style=" font-size: 1rem; font-weight: bold;">
            To: <br>
            {{ order.receiver_name }} <br>
            {{ order.receiver_address }} <br>
            {{ order.receiver_city }} - {{ order.receiver_state }} - {{ order.receiver_post_code }} <br>
            {{ countryById(order.country_id) ? countryById(order.country_id).name : '' }} <br>
            {{ order.receiver_country_id && countryById(order.receiver_country_id) ? countryById(order.receiver_country_id).name : '' }} <br>
            Ref: {{ order.package_name }}
          </div>
        </div>
      </div>
      <div class="last-mile-name">{{ order.carrier }}</div>
      <div class="last-mile-barcode">
        <VueBarcode v-if="order.tracking_number !== null" style=" top: 50%; left: 50%; transform: translate(0%, -25%); width: 100%;"
                    :width="3"
                    :font-size="30"
                    :value="order.tracking_number">
          {{ order.tracking_number }}
        </VueBarcode>
      </div>
      <div class="order-note">
        {{ order.note ? 'NOTE: ' + order.note : '' }}
      </div>
    </v-card>
  </div>
</template>
<script>
import VueBarcode from "vue-barcode";
import {mapGetters} from "vuex";

export default {
  name: 'LabelDefault',
  components: {
    VueBarcode
  },
  props: [
    'printSubPage',
    'order'
  ],
  computed: {
    ...mapGetters({
      countries: 'country/all',
      countryById: 'country/getById',
      services: 'service/all',
      serviceName: 'service/serviceName',

    }),
    labelTitle() {
      let country = this.countryById(this.order.country_id);
      let title =  this.serviceName(this.order.service_id);
      if(country) title += ' TO ' + country.country_code;
      return title;
    },
  },
}
</script>
<style scoped lang="scss">
.page {
  width: 210mm;
  // height: 320.13mm;
  border-radius: 0;
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

@page {
  margin: 0;
}

.print-order-default {
  width: 100mm;
  // height: 160mm;
  border: 1px solid black;
  zoom: 198%;
  clear: both;
  margin: auto;
  overflow: hidden;
  border-radius: 0;

  .header {
    height: 2.5cm;
    width: 100%;
    border-bottom: 1px solid black;

    .logo {
      width: 2.5cm;
      height: 2.5cm;
      border-right: 1px solid black;
      float: left;
    }

    .epacket-barcode {
      float: right;
      width: calc(7.5cm - 3px);
      height: 2.5cm;

      .block-barcode {
        position: relative;
        display: inline-block;
        height: 19mm;
        overflow: hidden;
      }
    }
  }

  .label-title {
    min-height: 0.5cm;
    border-bottom: 1px solid black;
    text-align: center;
    padding-bottom: 4px;
    padding-top: 4px;
  }

  .label-content {
    height: auto;
    border-bottom: 1px solid black;
    font-size: 0.35cm;
    padding: 0.2cm 0.2cm 0 0.2cm;

    .label-from {
      display: inline-block;
      width: 9.6cm;

      .label-address {
        width: 4.8cm;
        float: left;
      }

      .label-items {
        width: calc(4.8cm - 15px);
        padding-left: 9px;
        float: right;
      }
    }

    .label-to {
      position: relative;
    }
  }

  .last-mile-name {
    height: 0.5cm;
    border-bottom: 1px solid black;
    text-align: center;
  }

  .last-mile-barcode {
    height: 3cm;
    border-bottom: 1px solid black;
    position: relative;
    overflow: hidden;
  }

  .order-note {
    // height: 4cm;
  }
}
</style>
<style>
.block-barcode .vue-barcode-element {
  width: 7.4cm;
}

.last-mile-barcode .vue-barcode-element {
  width: 99mm;
}
</style>
